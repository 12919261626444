import styled from '@xstyled/styled-components'
import { Divider } from '../social'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledDivider = styled(Divider)`
  margin: 25px;
  position: relative;
`
