import React, { useRef, useLayoutEffect } from 'react'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Text } from '@/components/Text'
import { Wrapper, StyledDivider } from './styled'
import { MobileFooter } from '@/layouts/styled'
import MobileBack from '@/components/MobileBack'
import gsap from 'gsap'

const PlanetPage: React.FC = () => {
  const { t } = useTranslation()
  const dividerRef = useRef(null)

  useLayoutEffect(() => {
    const tl = gsap.timeline({})
    tl.fromTo(
      dividerRef.current,
      1.5,
      {
        scaleX: 0,
      },
      {
        scaleX: 1,
        ease: 'sine.out',
      },
      0.8,
    )
  })
  return (
    <>
      <Page
        content={
          <PageWrapper>
            <Wrapper>
              <Text
                title={t('dna.join_toggle4_title', {
                  defaultValue: 'WE TAKE PERSONAL INITIATIVE',
                })}
                text={t('dna.join_toggle4_body', {
                  defaultValue:
                    'At Ohpen you get and take the space to start things. We like it when people take initiative. People that come and work at Ohpen, are that way. We do not take you by the hand. You own your job and you can and will do what it takes to get to the best result. We trust you to take that jump and just do it.',
                })}
              />
              <StyledDivider ref={dividerRef} />
            </Wrapper>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
          isSubPage: true,
        }}
      />
      <MobileFooter id="mobile-footer">
        <MobileBack
          variant="light"
          delay={0}
          time={1}
          url="/our-dna/values"
          text="back"
        />
      </MobileFooter>
    </>
  )
}

export default PlanetPage
