import React, { useRef, useState } from 'react'
import styled, { css, Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import { H } from '@/components/Heading'
import { P } from '@/components/Paragraph'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { SwitchTransition, Transition } from 'react-transition-group'
import { useContentInProp } from '@/hooks/useContentInProp'
import { SplitText } from 'gsap/dist/SplitText'
import gsap from 'gsap'
import { breakpoints } from '@xstyled/system'
import proportion from '@/modules/utils/proportion'
import { TextNavigationLink } from '@/components/TextNavigationLink'
import Links from '@/components/Links'

const Title = styled(H)`
  overflow: hidden;
`

const TabItem = styled(P)<{ active: boolean }>`
  opacity: ${(props) => (props.active ? 1 : 0.4)};
`

const CustomBreakPoint = styled(Box)`
  ${breakpoints({
    /* eslint-disable sort-keys */
    md: css`
      min-height: 620px;
    `,
    /* eslint-enable sort-keys */
  })}
`

const getTabData = (t) => [
  {
    tab: t('dna.social_toggle1'),
    title: t('dna.social_toggle1_title'),
    body: t('dna.social_toggle1_body'),
    url: '/our-dna/social/planet',
  },
  {
    tab: t('dna.social_toggle2'),
    title: t('dna.social_toggle2_title'),
    body: t('dna.social_toggle2_body'),
    url: '/our-dna/social/people',
  },
]

const TabView = styled(Box)`
  position: relative;

  ${breakpoints({
    md: css`
      padding-bottom: ${proportion(32, 'desktop')};
    `,
    xl: css`
      padding-bottom: 32px;
    `,
  })}
`

export const Divider = styled.div`
  display: block;
  transform: scaleX(0);
  transform-origin: left;
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 48px;
  background-color: secondary;
`

const ActiveTab = ({ active, title, body }) => {
  const inProp = useContentInProp()
  const bodyRef = useRef(null)
  const titleRef = useRef(null)
  const dividerRef = useRef(null)

  function onExit(el) {
    gsap.fromTo(
      el,
      0.8,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        ease: 'sine.inOut',
      },
    )
  }

  function onEnter() {
    const tl = gsap.timeline({})
    const bodySt = new SplitText(bodyRef.current, {
      type: 'words',
    })
    const titleSt = new SplitText(titleRef.current, {
      charsClass: 'char',
      type: 'chars',
    })

    tl.fromTo(
      titleSt.chars,
      0.8,
      {
        y: 40,
      },
      {
        force3D: true,
        ease: 'sine.out',
        stagger: 0.02,
        y: 0,
      },
      0.4,
    )

    tl.fromTo(
      bodySt.words,
      0.8,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        stagger: 0.014,
        ease: 'sine.inOut',
      },
      0.4,
    )

    tl.fromTo(
      dividerRef.current,
      0.5,
      {
        scaleX: 0,
      },
      {
        scaleX: 1,
        ease: 'sine.out',
      },
      0.8,
    )
  }

  return (
    inProp && (
      <SwitchTransition mode="out-in">
        <Transition
          key={active}
          appear
          onEnter={onEnter}
          onExit={onExit}
          timeout={1300}
        >
          <TabView>
            <Title variant="small" ref={titleRef}>
              {title}
            </Title>
            <P variant="bodyLarge" ref={bodyRef}>
              {body}
            </P>
            <Divider ref={dividerRef} />
          </TabView>
        </Transition>
      </SwitchTransition>
    )
  )
}

const SocialPage: React.FC = () => {
  const inProp = useContentInProp()
  const { t } = useTranslation()

  const texts = {
    left: {
      link: t('dna.social_cta'),
      pretitle: t('dna.social_super'),
      text: t('dna.social_body'),
      title: t('dna.social_title'),
      url: '/our-dna/followup',
    },
  }

  const tabData = getTabData(t)
  const containerRef = useRef(null)
  const [activeTab, setActiveTab] = useState(0)
  const { title, body } = tabData[activeTab]

  function onClickTab(index: number) {
    return () => setActiveTab(index)
  }

  function onEnter(el) {
    gsap.fromTo(
      el.children,
      1,
      {
        opacity: 0,
      },
      {
        ease: 'sine.inOut',
        opacity: 1,
        delay: 1.1,
        stagger: {
          each: 0.1,
        },
      },
    )
  }

  const left = (
    <Text {...texts.left}>
      <Transition in={inProp} onEnter={onEnter} timeout={1000}>
        <Box ref={containerRef}>
          {tabData.map((item, index) => (
            <Box key={item.tab}>
              <TabItem
                variant="link"
                active={index === activeTab}
                onClick={onClickTab(index)}
                data-url
              >
                {item.tab}
              </TabItem>
            </Box>
          ))}
        </Box>
      </Transition>
      <TextNavigationLink
        variant="dark"
        delay={1.2}
        time={1}
        url={texts.left.url}
        text={texts.left.link}
      />
    </Text>
  )

  return (
    <>
      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              mobileContent={[
                <>
                  <Text isMobile {...texts.left}>
                    <Links
                      text={texts.left.link}
                      url={texts.left.url}
                      links={tabData}
                    />
                  </Text>
                </>,
              ]}
            >
              <Box row>
                <Box col={0.5}>
                  <Box col={0.8}>{left}</Box>
                </Box>
                <CustomBreakPoint
                  col={0.5}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Box col={0.8}>
                    <ActiveTab active={activeTab} title={title} body={body} />
                  </Box>
                </CustomBreakPoint>
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}

export default SocialPage
